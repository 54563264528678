import React, {useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import Welcome from './questionnaire/Welcome';
import Question from './questionnaire/Question';
import Email from './questionnaire/Email';

function Questionnaire({onQuestionnaireSubmit, ...questionnaire}) {

  const {text, assets, config, style, questions} = questionnaire;

  const userAnswersInitial =  Object.fromEntries(Object.entries(questions).map(([k, {answers}]) => [k, Object.fromEntries(Object.entries(answers).map(([k, v]) => [k, false]))]));
  
  const [userAnswers, setUserAnswers] = useState(userAnswersInitial);

  const questionProps = {
    text: text?.question,
    assets: assets?.question,
    config: config?.question,
    style: style?.question
  }

  const welcomeProps = {
    text: text?.welcome,
    assets: assets?.welcome,
    config: config?.welcome,
    style: style?.welcome
  }

  const emailProps = {
    text: text?.email,
    assets: assets?.email,
    config: config?.email,
    style: style?.email
  }

  const answerData = Object.fromEntries(Object.entries(userAnswers).map(([k, v]) => [k, Object.entries(v).filter(([key, value]) => !!value).map(([k,v]) => parseInt(k))]));

  const onEmailSubmit = formData => onQuestionnaireSubmit({...formData, answers: answerData});

  const onEmailSkip = () => onQuestionnaireSubmit({answers: answerData});

  const updateAnswers = id => answers => setUserAnswers(prevState => ({...prevState, [id]: answers}));

  const defaultQuestionOrder = questionnaire.config.question.default_question_order;

  const firstQuestionId = defaultQuestionOrder[0] || null;

  const getDefaultNextQuestionId = id =>defaultQuestionOrder[defaultQuestionOrder.findIndex(i => i === parseInt(id)) + 1] || null;

  return (
    <Switch>
        <Route path="/question/:id?" 
          render={({ match: { params: { id = firstQuestionId} } }) => 
            <Question {...{question: questions[id], userAnswers: userAnswers[id], updateAnswers: updateAnswers(id), noWelcome: config.welcome.skip && id === firstQuestionId, ...questionProps, defaultNextQuestionId: getDefaultNextQuestionId(id)}} />}
        />
        <Route path="/email" render={() => <Email  {...{onEmailSubmit, onEmailSkip, ...emailProps}} />} />
        <Route path="/" render={() => config.welcome.skip ? <Question {...{question: questions[firstQuestionId], userAnswers: userAnswers[firstQuestionId], updateAnswers: updateAnswers(firstQuestionId), noWelcome: config.welcome.skip, ...questionProps, defaultNextQuestionId: getDefaultNextQuestionId(firstQuestionId)}} /> : <Welcome {...welcomeProps} />} />
    </Switch>   
  );
}

export default Questionnaire;
