import React, {useContext} from 'react';
import {MejaContext} from '../../providers/MejaContext';

import mejaStyle from './Navigation.module.css';

function Navigation({nextText, previousText, continueText, showContinue, hideNext, hidePrevious, next, previous}) {
  const {utils: {getMejaAssetUrl: mejaAsset}} = useContext(MejaContext);


  const iconNext = mejaAsset('chevron-right_minor.svg');
  const iconPrevious = mejaAsset('chevron-left_minor.svg');

  return (
      <div className={mejaStyle.container}>
        {hidePrevious ?
        <div className={`${mejaStyle.previous} ${mejaStyle.nopointer}`}></div>
        :
        <div
          className={mejaStyle.previous}
          onClick={previous}
          onKeyPress={previous}
          role="button"
          tabIndex={0}
        >
          <div className={mejaStyle.box}>
            <div className={mejaStyle.text}>
              {previousText}
            </div>
          </div>
          <img
            className={mejaStyle.icon}
            src={iconPrevious}
            alt={previousText}
          />
          
        </div>
        }
        {hideNext ?
        <div className={`${mejaStyle.next} ${mejaStyle.nopointer}`}></div>
        : 
        <div
        className={`${mejaStyle.next} ${showContinue ? mejaStyle.show : ''}`}
          onClick={next}
          onKeyPress={next}
          role="button"
          tabIndex={0}
        >
          <img
            className={mejaStyle.icon}
            src={iconNext}
            alt={showContinue ? continueText : nextText}
          />
          <div className={mejaStyle.box}>
            <div className={mejaStyle.text}>
              {showContinue ? continueText : nextText}
            </div>
          </div>
        </div>}
      </div>
  );
}

export default Navigation;
