import React, { useContext } from 'react';

import MejaContext from '../providers/MejaContext';

import mejaStyle from './Calculation.module.css';

function Calculation({ text, assets, config, style }) {

  const { utils: { getStoreAssetUrl: storeAsset } } = useContext(MejaContext);

  return (
    <div className={mejaStyle.container}>
      <div className={`meja-header ${mejaStyle.header}`}>{text.header}</div>
      <video autoPlay loop className={mejaStyle.spinner} playsInline>
        <source
          src={storeAsset(assets.spinner)}
          type="video/mp4"
        />
      </video>
      <p className={`meja-subheader ${mejaStyle.subheader}`}>{text.subheader}</p>
    </div>
  );
}

export default Calculation;
