import React, {useState} from 'react';

import ProductTile from './ProductTile';

import mejaStyle from './Results.module.css';

function Results({results, onRestart, text, config}) {
  const {
    recommendation: {results: products},
  } = results;

  const {results_soft_limit: softLimit, results_hard_limit: hardLimitConfig} = config;
  const hardLimit = Math.min(hardLimitConfig, products.length);

  const [displayCount, setDisplayCount] = useState(parseInt(softLimit, 10));

  const increaseDisplayCount = () =>
    setDisplayCount(prev => Math.min(prev + parseInt(softLimit, 10), parseInt(hardLimit, 10)));

  const displayProducts = products.slice(0, Math.min(displayCount, products.length));

  const productsTiles = displayProducts.map((product, idx) => (
    <ProductTile key={idx} {...{product, text}} />
  ));

  return (
    <div className={mejaStyle.container}>
      <div className={`meja-header ${mejaStyle.header}`}>{text.header}</div>
      <div className={mejaStyle.results}>{productsTiles}</div>
      {displayCount < hardLimit && (
        <div>
          <button
            type="button"
            className={`meja-button-primary ${mejaStyle.button}`}
            onClick={increaseDisplayCount}
          >
            {text.show_more}
          </button>
        </div>
      )}
      <div className={`meja-subheader ${mejaStyle.subheader}`}>{text.subheader}</div>
      <div
        role="link"
        tabIndex={0}
        className={`meja-link-subdued ${mejaStyle.restart}`}
        onClick={onRestart}
        onKeyDown={onRestart}
      >
        {text.restart}
      </div>
    </div>
  );
}

export default Results;
