import React,{useContext} from 'react';
import {MejaContext} from '../providers/MejaContext';

import mejaStyle from './ProductTile.module.css';

function ProductTile({product, text}) {

  const {utils: {getProductUrl: productUrl}} = useContext(MejaContext);

  return (
    <a href={productUrl(product.handle)} className={mejaStyle.container}>
      <div className={mejaStyle.imagecontainer}>
        <img className={mejaStyle.image} src={product.image.src} alt={product.image.alt} /> 
      </div>
      <div className={mejaStyle.title}>{product.title}</div>
      <div className={mejaStyle.price}>
        {text.from ? `${text.from} ` : ''}{text.currency} {product.price}
      </div>
    </a>
  );
}

export default ProductTile;
