import React from 'react';
import { useHistory } from 'react-router-dom';
import Anwers from './answers/Answers';
import Navigation from './Navigation';

import mejaStyle from './Question.module.css';

function Question({ question, question: { answers }, userAnswers, updateAnswers, text, noWelcome, defaultNextQuestionId }) {

  const history = useHistory();

  const next = () =>
  defaultNextQuestionId
      ? history.push(`/question/${defaultNextQuestionId}`)
      : history.push('/email');

  const previous = () => history.goBack();

  //TODO: Handle next_question parameter on next and on answer change

  const onAnswerChange = answer => {
    updateAnswers(answer);
    if (!question.config.multiple_choice && Object.entries(answer).filter(([key, value]) => !!value).length > 0) {
      next();
    }
  }

  const showContinue = Object.entries(userAnswers).filter(([key, value]) => !!value).length > 0;
  const hideNext = !question.config.optional && Object.entries(userAnswers).filter(([key, value]) => !!value).length === 0;

  return (
    <div className={mejaStyle.container}>
      <div className={`meja-header ${mejaStyle.question}`}>{question.text.question}</div>
      {question.text.sub &&
        <div className={`meja-subheader ${mejaStyle.sub}`} >{question.text.sub}</div>
      }
      <Anwers {...{ answers, userAnswers, onAnswerChange, multipleChoice: question.config.multiple_choice, order: question.config.answer_order }} />
      <Navigation {...{ nextText: text.next, previousText: text.previous, continueText: text.continue, showContinue, hideNext, hidePrevious: noWelcome, next, previous }} />
    </div>
  );
}

export default Question;
