import React, {useContext} from 'react';
import {MejaContext} from '../../../providers/MejaContext';
import mejaStyle from './Answer.module.css';

function Answer({text, assets, selected, toggleAnswerSelection}) {
  const {utils: {getStoreAssetUrl: storeAsset}} = useContext(MejaContext);

  return (
    <div className={`${mejaStyle.box} ${selected ? mejaStyle.selected : ''}`} onClick={toggleAnswerSelection}>
      {assets?.image && <img
        className={mejaStyle.image}
        src={storeAsset(assets.image)}
        alt={text.answer}
      />}
      {text?.answer && <div className={`text ${mejaStyle.text}`}>{text.answer}</div>}
    </div>
  );
}

export default Answer;
