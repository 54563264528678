import React from 'react';

export const MejaContext = React.createContext(null);

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

const utils = context => ({
  getMejaAssetUrl: assetFileName => context.assets_url + assetFileName,
  getStoreAssetUrl: assetFileName => IS_EXTERNAL_LINK_REGEX.test(assetFileName) ? assetFileName : context.store_assets_url + assetFileName,
  getProductUrl: productHandle => `https://${context.shop}/products/${productHandle}`,
});

export const MejaProvider = ({ context, defaultStyle, children }) => {
  return <MejaContext.Provider value={{ context, defaultStyle, utils: utils(context) }}>{children}</MejaContext.Provider>
};

export default MejaContext;
