
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import Meja from './Meja';

import './index.css';

import mejaMockData from './mock/meja-data-elephbo';

let data;
if (process.env.NODE_ENV === 'development') {
  data = mejaMockData;
  console.log('meja-data', data);
} else {
  data = JSON.parse(document.getElementById('meja-data').textContent);
}

ReactDOM.render(
  <React.StrictMode>
    <Router basename={`${data.path_prefix}/${data.slug}`}>
      <Meja {...{data}} />
    </Router>
  </React.StrictMode>,
  document.getElementById('meja-questionnaire')
);
