import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

function ScrollToTop({history, children, scrollTo}) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, scrollTo);
    });
    return () => {
      unlisten();
    };
  });

  return <>{children}</>;
}

ScrollToTop.propTypes = {
  history: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ScrollToTop.defaultProps = {
  history: null,
  children: null
};

export default withRouter(ScrollToTop);
