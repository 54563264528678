import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MejaContext } from '../../providers/MejaContext';

import mejaStyle from './Welcome.module.css';

function Welcome({ text, assets, config, style }) {

  const { utils: { getStoreAssetUrl: storeAsset } } = useContext(MejaContext);

  const { push } = useHistory();

  return (
    <div className={mejaStyle.container}>
      <div className={mejaStyle.imagecontainer}>
        <img className={mejaStyle.image} src={storeAsset(assets.image)} alt="" />
      </div>
      <div className={mejaStyle.content}>
        <div className={`meja-header ${mejaStyle.header}`}>{text.header}</div>
        <div className={`meja-subheader ${mejaStyle.subheader}`}>{text.subheader}</div>
        <button type="button" className={`meja-button-primary ${mejaStyle.button}`} onClick={() => push('/question')} >
          {text.button}
        </button>
      </div>
    </div>
  );
}

export default Welcome;
