import React from 'react';
import Answer from './Answer';

import mejaStyle from './Answers.module.css';

function Answers({answers, userAnswers, onAnswerChange, multipleChoice, order}) {

  const orderAnswers = (answers, order) => {
    let orderedAnswers = [];
    order.forEach((id) => {
      if (answers.find((q) => q.id === id))
      orderedAnswers.push(answers.find((q) => q.id === id));
    });
    return orderedAnswers;
  };

  const allAnswersUnselected = Object.fromEntries(Object.entries(answers).map(([k, v]) => [k, false]));

  const toggleAnswerSelection = id => () => {
    if(multipleChoice) {
      onAnswerChange({...userAnswers, [id]: !userAnswers[id]});
    } else {
      onAnswerChange({...allAnswersUnselected, [id]: !userAnswers[id]});
    }
  }

  const answerTiles = orderAnswers(Object.values(answers), order).map((answer, index) => 
    <Answer key={answer.id} {...{...answer, selected: userAnswers[answer.id], toggleAnswerSelection: toggleAnswerSelection(answer.id)}} />
  )

  return (
    <div className={mejaStyle.container}>
      {answerTiles}
    </div>
  );
}

export default Answers;
