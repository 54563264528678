import React, { useContext } from 'react';
import { MejaContext } from '../../providers/MejaContext';
import { useForm } from "react-hook-form";

import mejaStyle from './Email.module.css';

function Email({ onEmailSubmit, onEmailSkip, text, assets, config }) {

  const { utils: { getStoreAssetUrl: storeAsset } } = useContext(MejaContext);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  return (
    <div className={mejaStyle.container}>
      <div className={mejaStyle.imagecontainer}>
        <img

          className={mejaStyle.image}
          src={storeAsset(assets.image)}
          alt=""
        />
      </div>
      <div className={mejaStyle.content}>
        <div className={`meja-header ${mejaStyle.header}`}>{text.header}</div>
        <div className={`meja-subheader ${mejaStyle.subheader}`}>{text.subheader}</div>
        <form onSubmit={handleSubmit(onEmailSubmit)}>
          {config.ask_name &&
            <input
              type="text"
              className={`meja-input-text ${mejaStyle.input} ${errors.name ? 'meja-input-text-error' : ''}`}
              placeholder={text.name_placeholder}
              key="name"
              {...register("name", { required: config.name_required })}
            />
          }
          {errors.name && <div className={mejaStyle.error}>{text.name_error}</div>}
          <input
            type="text"
            className={`meja-input-text ${mejaStyle.input} ${errors.email ? 'meja-input-text-error' : ''}`}
            placeholder={text.email_placeholder}
            key="email"
            {...register("email", { required: config.email_required, pattern: emailRegex })}
          />
          {errors.email && <div className={mejaStyle.error}>{text.email_error}</div>}
          <input
            type="submit"
            className={`meja-button-primary ${mejaStyle.button}`}
            value={text.confirm_button_text}
          />
          {config.continue_without_email &&
            <div className={`meja-link-subdued ${mejaStyle.skip}`} onClick={onEmailSkip}>
              {text.continue_without_email_text}
            </div>}
        </form>
      </div>
    </div>
  );
}

export default Email;
