import React, { useEffect, useState, Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import axios from 'axios';
import Radium from 'radium';

import { MejaProvider } from './providers/MejaContext';
import ScrollToTop from './components/scrollToTop';
import Questionnaire from './components/Questionnaire';
import Results from './components/Results';
import Calculation from './components/Calculation';

import './Meja.css';

function Meja({ data }) {

  const { push } = useHistory();

  const { questionnaire, questionnaire: { style: { variables: styleVariables, defaults: defaultStyle } }, recommendation, ...context } = data;

  const [results, setResults] = useState({ loading: false, recommendation });

  // const [scrollTo, setScrollTo] = useState(0);
  const scrollTo = 0;

  const [containerHeight, setContainerHeight] = useState('700px');
  const containerRef = React.createRef();

  useEffect(() => {
    //if (scrollTo === 0) setScrollTo(containerRef.current.getBoundingClientRect().y - 50);
    setContainerHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    if (results.recommendation) push(`/results/${results.recommendation.slug}`);
  }, [results, push]);

  const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
  const resultEndpoint = `${protocol}://${context.shop}${context.path_prefix}/${context.slug}`;

  const onQuestionnaireSubmit = data => {
    setResults(prevState => ({ recommendation: prevState.recommendation, loading: true }));
    Promise.all([
      axios.post(resultEndpoint, data).then(res => setResults(prevState => ({ ...prevState, recommendation: res.data }))),
      new Promise((resolve, reject) => setTimeout(() => resolve(), questionnaire.config.calculation.min_waiting_time_ms)),
    ]).then(() => {
      setResults(prevState => ({ ...prevState, loading: false }));
    });
  };

  const onRestart = () => {
    setResults(prevState => ({ ...prevState, recommendation: null }));
    push('/');
  }

  const calculationProps = {
    text: questionnaire.text?.calculation,
    assets: questionnaire.assets?.calculation,
    config: questionnaire.config?.calculation,
    style: questionnaire.style?.calculation
  }

  const resultsProps = {
    text: questionnaire.text?.results,
    assets: questionnaire.assets?.results,
    config: questionnaire.config?.results,
    style: questionnaire.style?.results
  }

  return (
    <div id="meja-container" ref={containerRef} style={[{ minHeight: containerHeight }, styleVariables]}>
      <MejaProvider {...{ context, defaultStyle }}>
        <ScrollToTop {...{ scrollTo }}>
          <Suspense fallback={<></>}>

            {results.loading ?
              <Calculation {...calculationProps} />
              :
              <Switch>
                <Route path="/results/:slug?">
                  <Results {...{ results, onRestart, ...resultsProps }} />
                </Route>
                <Route path="/">
                  <Questionnaire {...{ onQuestionnaireSubmit, ...questionnaire }} />
                </Route>
              </Switch>
            }
          </Suspense>
        </ScrollToTop>
      </MejaProvider>
    </div>
  );
}

export default Radium(Meja);
